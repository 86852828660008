import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {CardGiftcardRounded} from "@mui/icons-material";
import {Stack, Tooltip} from "@mui/material";

const DialogFormAddEth = ({web3, accounts, giftBoxAddress}) => {
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState('');

  const openDialog = () => setOpen(true);
  const closeDialog = () => setOpen(false);

  const addEth = async () => {
    closeDialog();
    await web3.eth.sendTransaction({
      from: accounts[0],
      to: giftBoxAddress,
      value: amount
    });
  }

  const handleAmountChange = event => setAmount(event.target.value);

  return (
    <>
      <Tooltip title="Add ETH to the gift box">
        <Button variant="contained" startIcon={<CardGiftcardRounded/>} onClick={openDialog} size="small">ETH</Button>
      </Tooltip>
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>Add ETH</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Amount of ETH to add to the gift box
          </DialogContentText>
          <Stack>
            <TextField value={amount}
                       placeholder="1.0"
                       onChange={handleAmountChange}
                       variant="standard"/>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={addEth}>Enable</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default DialogFormAddEth;